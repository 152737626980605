export const environment = {
  production: true,
  host: 'https://api-dev.indeema.com/api/v1',
  BUCKET_API_URL: 'https://indeema-web-dev.s3.eu-central-1.amazonaws.com',
  pattern: {
    phone: /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}([\s][x]\d{0,8})?$/,
    email: '^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$',
    zip: /^\d{5}(-\d{4})?$/
  },
};
